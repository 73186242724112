<template>
  <b-card
    class="pricecheckitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="pricecheckitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售订单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="pricecheckitem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售订单编号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="pricecheckitem.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单明细Id  sales_order_item.item_id"
            label-for="sales_item_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_item_id"
              size="sm"
              v-model="pricecheckitem.sales_item_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="pricecheckitem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              v-model="pricecheckitem.code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="pricecheckitem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否赠品"
            label-for="is_gift"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_gift"
              size="sm"
              v-model="pricecheckitem.is_gift"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="成本价"
            label-for="cost_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost_price"
              size="sm"
              v-model="pricecheckitem.cost_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售指导价"
            label-for="guide_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="guide_price"
              size="sm"
              v-model="pricecheckitem.guide_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="活动Id   price_activity.aid"
            label-for="aid"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="aid"
              size="sm"
              v-model="pricecheckitem.aid"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="活动价格"
            label-for="activity_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="activity_price"
              size="sm"
              v-model="pricecheckitem.activity_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="含税单价"
            label-for="product_cost_tax"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_tax"
              size="sm"
              v-model="pricecheckitem.product_cost_tax"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="pricecheckitem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="pricecheckitem.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="memo"
              size="sm"
              v-model="pricecheckitem.memo"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import pricecheckitemStore from './pricecheckitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      pricecheckitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('pricecheckitem')) store.registerModule('pricecheckitem', pricecheckitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('pricecheckitem')) store.unregisterModule('pricecheckitem')
    })

    const edit = function() {
      store.dispatch('pricecheckitem/edit', {id: this.id}).then(res => {
        this.pricecheckitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('pricecheckitem/view', {id: this.id}).then(res => {
        this.pricecheckitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('pricecheckitem/save', this.pricecheckitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
